import { Route, Routes } from "react-router-dom";
import Layout from "./components/commen/Layout/Layout";
import Home from "./pages/Home";

import "swiper/css";

import MovieDetails from "./pages/MovieDetails";
import SearchMobile from "./pages/Search";
import SeriesDetails from "./pages/SeriesDetails";
import ViewAll from "./pages/ViewAll";
import About from "./pages/static/About";
import Contact from "./pages/static/Contact";
import Privacy from "./pages/static/Privacy";
import RefundPolicy from "./pages/static/Refund";
import Terms from "./pages/static/Terms";
function App() {
  return (
    <div className="App">
      <Routes>
        <Route
          path="/view-all/:id"
          element={
            <Layout>
              <ViewAll />
            </Layout>
          }
        />

        <Route
          path="/"
          element={
            <Layout active>
              <Home />
            </Layout>
          }
        />

        <Route
          path="/search"
          element={
            <Layout active>
              <SearchMobile />
            </Layout>
          }
        />
        <Route
          path="/movie/:id"
          element={
            <Layout>
              <MovieDetails />
            </Layout>
          }
        />
        <Route
          path="/series/:id"
          element={
            <Layout>
              <SeriesDetails />
            </Layout>
          }
        />

        <Route
          path="/termsandconditions"
          element={
            <Layout>
              <Terms />
            </Layout>
          }
        />
        <Route
          path="/privacyPolicy"
          element={
            <Layout>
              <Privacy />
            </Layout>
          }
        />
        <Route
          path="/about-us"
          element={
            <Layout>
              <About />
            </Layout>
          }
        />
        <Route
          path="/refund"
          element={
            <Layout>
              <RefundPolicy />
            </Layout>
          }
        />
        <Route
          path="/contact-us"
          element={
            <Layout>
              <Contact />
            </Layout>
          }
        />
      </Routes>
    </div>
  );
}

export default App;
