import React, { useEffect } from "react";
import { MdOutlineSearch } from "react-icons/md";
import { useLocation, useNavigate } from "react-router-dom";
import Logo from "../../../assets/logo.jpeg";
import { getUnreadNotifications, searchData } from "../../../utils/apis";
import useStore from "../../../utils/store";
import VideoCard from "../../VideoCards/VideoCard";
import Loading2 from "../Loading2";

const Navbar = ({ active, home, thirdParty }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const user = useStore((state) => state.user);

  const isLoggedIn = useStore((state) => state.isLoggedIn);
  const [search, setSearch] = React.useState("");

  const [data, setData] = React.useState([]);
  const [unreadNotificationCount, setUnreadNotificationCount] =
    React.useState(0);
  const [loading, setLoading] = React.useState(false);

  const activeCategory = useStore((state) => state.activeCategory);
  const setActiveCategory = useStore((state) => state.setActiveCategory);

  const getUnreadNotificationsHandler = async () => {
    const [success, data] = await getUnreadNotifications(user?.token);

    if (success) {
      setUnreadNotificationCount(data.notificationCount);
    }
  };

  const searchOnChange = async (e) => {
    setSearch(e.target.value);
    setLoading(true);
    const [success, data] = await searchData(e.target.value);
    if (success) {
      setData({
        movies: data.movies.filter((item) => item.isEnable),
        series: data.series.filter((item) => item.isEnable),
      });
    }
    setLoading(false);
  };

  useEffect(() => {
    if (isLoggedIn && user && !thirdParty) {
      getUnreadNotificationsHandler();
    }
  }, [user, isLoggedIn]);

  useEffect(() => {
    setSearch("");
  }, [location.pathname]);

  return (
    <>
      <nav className="relative hidden flex-col items-center justify-between gap-4 border-b bg-white py-6 px-6 md:flex md:flex-row md:py-3.5">
        <div className="flex gap-14">
          <img
            src={Logo}
            alt="logo"
            className="h-16 cursor-pointer md:h-16"
            onClick={() => navigate(thirdParty ? "/third-party" : "/")}
          />
          <div className="flex items-center gap-6 ">
            {home?.map((item) => (
              <button
                key={item._id}
                onClick={() => {
                  setActiveCategory(item._id);
                }}
                className={`flex items-center gap-2 rounded text-sm ${
                  activeCategory === item._id
                    ? ` text-primary underline decoration-primary-100 `
                    : ` text-text hover:text-primary`
                }   `}
              >
                {item.name}
              </button>
            ))}
          </div>
        </div>
        {!thirdParty && (
          <div className="flex w-full flex-col gap-6 md:w-auto md:flex-row">
            <div className="flex items-center gap-2 rounded border bg-white py-3 px-2 text-black md:py-2">
              <MdOutlineSearch />
              <input
                type="text"
                placeholder="Search"
                className="bg-transparent text-[13px] text-black outline-none "
                value={search}
                onChange={searchOnChange}
              />
            </div>
          </div>
        )}
        {search !== "" && (
          <div className="absolute top-full left-0 z-50 w-full border-b bg-white">
            {loading ? (
              <div className="flex h-full items-center justify-center">
                <Loading2 />
              </div>
            ) : (
              <div className="flex flex-col gap-2 px-4 py-2">
                <div className="text-[13px] font-bold text-black">
                  Search Results
                </div>
                {data.movies.length > 0 && (
                  <VideoCard
                    data={{
                      name: "Movies",
                      imageType: "Horizontal",
                      content: data.movies.map((item) => ({
                        item: item,
                        kind: "movie",
                      })),
                    }}
                  />
                )}
                {data.series.length > 0 && (
                  <VideoCard
                    data={{
                      name: "Series",
                      imageType: "Horizontal",
                      content: data.series.map((item) => ({
                        item: item,
                        kind: "series",
                      })),
                    }}
                  />
                )}
              </div>
            )}
          </div>
        )}
      </nav>
    </>
  );
};

export default Navbar;
