import React from "react";
import Static, { Item } from "../../components/commen/Static";

const Privacy = () => {
  return (
    <Static title={"Privacy Policy"}>
      <Item title={""}>
        This Privacy Policy (“Privacy Policy”) applies to Pocket Play Site/ App
        platforms including without limitation https://www.pocketplay.in
        (https://www.pocketplay.in) and other related Site/s or App/s, mobile
        applications and other online features each a “Site/s or App/s”). This
        Privacy Policy should be read in conjunction with the Terms of Use
        available on the “Site/s or App/s”. Personal Information/Data defined
        below of a user/s is collected if the user/s registers with the Site/s
        or App/s, accesses the Site/s or App/s or takes any action on the Site/s
        or App/s or uses or accesses the Services as defined in the Terms of
        Use. The terms ‘Personal Information’ and ‘Sensitive Personal
        Information or Information’ shall have the meaning ascribed to it under
        the Indian Information Technology Act, 2000 read with the Information
        Technology. WHAT DO WE DO WITH YOUR INFORMATION? When you subscribe to
        something from our packages as part of the buying and selling process,
        we collect the personal information you give us such as your name,
        address and email address. When you browse our packages, we also
        automatically receive your computer’s internet protocol (IP) address in
        order to provide us with information that helps us learn about your
        browser and operating system. Email marketing (if applicable): With your
        permission, we may send you emails about our store, new products and
        other updates. CONSENT When you provide us with personal information to
        complete a transaction, verify your credit card, we imply that you
        consent to our collecting it and using it for that specific reason only.
        If we ask for your personal information for a secondary reason, like
        marketing, we will either ask you directly for your expressed consent,
        or provide you with an opportunity to say no. HOW DO I WITHDRAW MY
        CONSENT? If after you opt-in, you change your mind, you may withdraw
        your consent for us to contact you, for the continued collection, use or
        disclosure of your information, at any time, by emailing us at
        support@pocketplay.in or contacting us at the address provided on the
        website. DISCLOSURE We may disclose your personal information if we are
        required by law to do so or if you violate our Terms of Service.
        THIRD-PARTY SERVICES In general, the third-party providers used by us
        will only collect, use and disclose your information to the extent
        necessary to allow them to perform the services they provide to us.
        However, certain third-party service providers, such as payment gateways
        and other payment transaction processors, have their own privacy
        policies in respect to the information we are required to provide to
        them for your purchase-related transactions. For these providers, we
        recommend that you read their privacy policies so you can understand the
        manner in which your personal information will be handled by these
        providers. In particular, remember that certain providers may be located
        in or have facilities that are located at different jurisdiction than
        either you or us. So if you elect to proceed with a transaction that
        involves the services of a third- party service provider, then your
        information may become subject to the laws of the jurisdiction(s) in
        which that service provider or its facilities are located. Once you
        leave our store’s website or are redirected to a third-party website or
        application, you are no longer governed by this Privacy Policy or our
        website’s Terms of Service. our website’s Terms of Service. LINKS When
        you click on links on our website for subscription, they may direct you
        away from our site. We are not responsible for the privacy practices of
        other sites and encourage you to read their privacy statements. SECURITY
        To protect your personal information, we take reasonable precautions and
        follow industry best practices to make sure it is not inappropriately
        lost, misused, accessed, disclosed, altered or destroyed. COOKIES We use
        cookies to maintain session of user/s. It is not used to personally
        identify you on other websites. AGE OF CONSENT By using this site, you
        represent that you are at least the age of majority in your state or
        province of residence, or that you are the age of majority in your state
        or province of residence and you have given us your consent to allow any
        of your minor dependents to use this site. CHANGES TO THIS PRIVACY
        POLICY We reserve the right to modify this privacy policy at any time,
        so please review it frequently. Changes and clarifications will take
        effect immediately upon their posting on the website. If we make
        material changes to this policy, we will notify you here that it has
        been updated, so that you are aware of what information we collect, how
        we use it, and under what circumstances, if any, we use and/or disclose
        it. If our APP is acquired or merged with another company, your
        information may be transferred to the new owners so that we may continue
        to provide services to you. QUESTIONS AND CONTACT INFORMATION If you
        would like to: access, correct, amend or delete any personal information
        we have, register a complaint, or simply want more information, mail our
        Privacy Compliance Officer at support@pocketplay.in Or by contacting at
        the company contact which is hereby incorporated by this reference into
        the Terms of Service. the Terms of Service.
      </Item>
    </Static>
  );
};

export default Privacy;
