import React from "react";
import { useNavigate } from "react-router-dom";
import img1 from "../../../assets/appstore/1.png";

const Footer = () => {
  const navigate = useNavigate();
  const links = [
    {
      title: "About",
      path: "/about-us",
    },
    {
      title: "Terms of Service",
      path: "/termsandconditions",
    },
    {
      title: "Privacy Policy",
      path: "/privacyPolicy",
    },
    {
      title: "Refund",
      path: "/refund",
    },
    {
      title: "Contact Us",
      path: "/contact-us",
    },
  ];
  return (
    <div className="flex flex-col justify-between gap-6 border-t  py-6 px-10 md:flex-row md:items-center">
      <div className="flex flex-col gap-6 md:flex-row">
        {links.map((link, index) => (
          <div
            onClick={() => navigate(link.path)}
            key={index}
            className="cursor-pointer text-[13px]  text-text hover:text-primary"
          >
            {link.title}
          </div>
        ))}
      </div>
      <div className="flex gap-6">
        <a
          href="https://play.google.com/store/apps/details?id=com.ott.pocket_play"
          target="_blank"
        >
          <img src={img1} alt="appstore" className="h-10 md:h-8" />
        </a>
      </div>
    </div>
  );
};

export default Footer;
