import React, { useEffect } from "react";
import { BiMenu } from "react-icons/bi";
import { BsArrowRight, BsSearch } from "react-icons/bs";
import { MdClose } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import Logo from "../../../assets/logo.jpeg";
import useStore from "../../../utils/store";
const MobileNavbar = ({ home, thirdParty }) => {
  const activeCategory = useStore((state) => state.activeCategory);
  const setActiveCategory = useStore((state) => state.setActiveCategory);
  const isLoggedIn = useStore((state) => state.isLoggedIn);
  const user = useStore((state) => state.user);
  const [loading, setLoading] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const navigate = useNavigate();

  return (
    <nav className=" flex items-center justify-between border-b bg-white px-6 py-4 md:hidden">
      {open && (
        <div className="fixed inset-0 z-[50] flex flex-col gap-6 bg-white px-4 py-6">
          <div className="flex items-center justify-between">
            <img src={Logo} alt="logo" className="w-10" />
            <button onClick={() => setOpen(false)}>
              <MdClose />
            </button>
          </div>
          <div className=" flex flex-col  ">
            {home?.map((item) => (
              <button
                key={item._id}
                onClick={() => {
                  setActiveCategory(item._id);
                  setOpen(false);
                  navigate(`/`);
                }}
                className={`flex  items-center justify-between gap-2 rounded border-b py-3 text-lg ${
                  activeCategory === item._id
                    ? ` text-primary-100 `
                    : ` text-text hover:text-primary`
                }   `}
              >
                {item.name}
                <BsArrowRight />
              </button>
            ))}
          </div>
        </div>
      )}
      <div className="flex items-center gap-4">
        <button onClick={() => setOpen(true)}>
          <BiMenu size={28} />
        </button>
        <img
          src={Logo}
          alt="logo"
          className=" mr-auto w-10"
          onClick={() => {
            navigate(thirdParty ? "/third-party" : "/");
          }}
        />
      </div>

      <div className="ml-auto flex gap-2">
        <button
          onClick={() => {
            navigate("/search");
          }}
          className="rounded-full bg-white bg-opacity-25 p-3 text-xl text-text"
        >
          <BsSearch />
        </button>
      </div>
    </nav>
  );
};

export default MobileNavbar;
