import create from "zustand";
import { persist, devtools } from "zustand/middleware";

let store = (set) => ({
  isLoggedIn: false,
  user: null,
  activeCategory: null,
  homepage: [],
  setActiveCategory: (category) =>
    set((state) => ({ activeCategory: category })),
  setHome: (homepage) => set((state) => ({ homepage: homepage })),
  login: (user) =>
    set((state) => ({
      isLoggedIn: true,
      user: user,
    })),
  updateUser: (user) => set((state) => ({ user: user })),
  logout: () => set((state) => ({ isLoggedIn: false, user: null })),
});

store = persist(store, { name: "pocket_play_settings" });
store = devtools(store);

const useStore = create(store);

export default useStore;
