import React from "react";
const Static = ({ children, title }) => {
  return (
    <div>
      <div className="relative bg-movies bg-[length:500px_auto] ">
        <div className="  bg-black bg-opacity-80 py-20">
          <h1 className="text-center text-4xl font-bold text-white">{title}</h1>
        </div>
      </div>
      <div className="mx-auto flex w-[95%] flex-col gap-6 py-10 md:w-2/3 ">
        {children}
      </div>
    </div>
  );
};

export default Static;

export const Item = ({ children, title }) => {
  return (
    <div className="flex flex-col  gap-3 text-black">
      <h1 className="text-xl font-bold ">{title}</h1>
      <p className="text-sm">{children}</p>
    </div>
  );
};
