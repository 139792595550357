import React from "react";
import Static, { Item } from "../../components/commen/Static";

const Terms = () => {
  return (
    <Static title={"Terms & Conditions"}>
      <Item title="">
        Terms And Conditions Pocket Play under company "A S C Digital
        Private Limited" Terms of Use PLEASE READ YOUR USE OF THE Pocket Play
        AVOD/SVOD OTT PLATFORM IS SUBJECT TO THESE TERMS OF USE AND OUR PRIVACY
        POLICY. BY USING THE SITE, YOU CONSENT TO THE COLLECTION AND USE OF YOUR
        DATA IN ACCORDANCE WITH OUR PRIVACY POLICY These Terms of Use (“Terms”)
        applies to (“Pocket Play”) digital platforms including without
        limitation www.pocketplay.in and other related websites, mobile
        applications and other online features that link to this Terms (each a
        “Site(s)/App(s)”). Pocket Play provides a personalized subscription
        service that allows our members to access Films, Short Films, Web Series
        and shows ("Content") streamed over the Internet to certain
        Internet-connected devices ("Pocket Play ready devices"). These Terms
        of Use govern your use of our service. As used in these Terms of Use,
        "Pocket Play service", "our service" or "the service" means the
        personalized service provided by Pocket Play for discovering and
        watching Pocket Play content, including all features and
        functionalities, recommendations and reviews, the website, and user
        interfaces, as well as all content and software associated with our
        service. The content on Pocket Play and all copyrights, patents,
        trademarks, service marks, trade names and all other intellectual
        property rights therein are owned by Pocket Play and/or its licensors
        or group companies and are protected by applicable Indian and
        international copyright and other intellectual property laws. You
        acknowledge, understand and agree that you shall not have, nor be
        entitled to claim, any rights in and to the Website/ Application
        content/ services and/or any portion thereof. You agree not to copy,
        reproduce, duplicate, stream, capture, archive, upload, download,
        publish, broadcast, sell, resell, edit, modify, manipulate, translate,
        decompile, disassemble, reverse engineer or exploit for any purposes the
        content or any portion of Website/ Application, including, without
        limitation, the Content and the Marks, except as authorized by these
        TERMS or as otherwise authorized in writing by Pocket Play. In
        addition, You are strictly prohibited from creating derivative works, or
        materials that otherwise are derived from or based on in any way the
        Content and the Marks, including montages, mash-ups and similar videos,
        wallpaper, desktop themes, greeting cards, and merchandise, except as
        authorized by these TERMS or as otherwise authorized in writing by
        Pocket Play. You must abide by all copyright notices, information,
        and restrictions contained in or associated with any Content. You must
        not remove, alter, interfere with, or circumvent any copyright,
        trademark, or other proprietary notices marked on the Content or any
        digital rights management mechanism, device or other content protection
        or access control measure (including, without limitation, Geo-filtering
        and/or encryption) associated with the Content. You hereby agree that
        all intellectual property rights, title and interest in the
        user-generated content published or generated on Website/ Application by
        You shall vest with Pocket Play. 1. AGE: Unless otherwise specified,
        Pocket Play is available for individuals who have attained the age of
        majority in their jurisdiction. In the case of Indian jurisdiction, this
        service is restricted to Users below the age of 18 also referred to as
        minors. If You are under the relevant age of majority in Your
        jurisdiction, You may only access the Website/ Application and avail the
        Services only in accordance with applicable law which enables a minor in
        your jurisdiction to access such a service. In India, by browsing or
        downloading and/or installing and/or using Pocket Play, You represent
        and warrant that YOU are 18 years of age or older and in case YOU are
        less than 18 years of age, You have taken consent of Your parent or
        guardian. SOME CONTENT OFFERED ON THE SITE MAY NOT BE SUITABLE FOR SOME
        VIEWERS AND THEREFORE VIEWER'S DISCRETION IS ADVISED. ALSO, SOME CONTENT
        OFFERED ON THE SITE MAY NOT BE APPROPRIATE FOR VIEWERSHIP BY MINORS.
        PARENTS AND/OR LEGAL GUARDIANS ARE ADVISED TO EXERCISE DISCRETION BEFORE
        ALLOWING THEIR CHILDREN AND/OR WARDS TO ACCESS CONTENT ON THIS WEBSITE.
        2. PERSONAL USE: The Pocket Play service and any content viewed
        through the service are for your personal and non-commercial use only
        and may not be shared with individuals beyond your household. During
        your Pocket Play membership we grant you a limited, non-exclusive,
        non-transferable right to access the Pocket Play service and view
        Pocket Play content. Except for the foregoing, no right, title or
        interest shall be transferred to you. You agree not to use the service
        for public performances. You further agree not to use our packages for
        any illegal or unauthorized purpose nor may you, in the use of the
        Service, violate any laws in your jurisdiction including but not limited
        to copyright laws. You must not transmit any worms or viruses or any
        code of a destructive nature. A breach or violation of any of the Terms
        will result in immediate termination of your Services and further
        actions and remedies as provided in law. Pocket Play shall have the
        discretion to make certain or all Content that is a part of the
        Subscription available to You on either one or limited number of end
        user device concurrently. 3. GEOGRAPHICAL CONDITION: Pocket Play is
        available in select countries only. Pocket Play would not be held
        responsible for hindrances in the access and use of Pocket Play due to
        geographical change in Your location. Further, YOU will be solely liable
        for accessing and using Pocket Play in the countries of Your use.
        Your subscription to Pocket Play in a particular geographical
        territory shall be valid for that territory only and shall not
        automatically entitle you to access your account from a different
        geographical territory, unless specifically permitted by Pocket Play.
        You will be required to re-subscribe to Pocket Play in the event that
        you wish to use the services from a different territory other than the
        one from where you originally subscribed to Pocket Play. 4.
        MEMBERSHIP: You will be solely responsible for obtaining and maintaining
        the Device and Internet connection needed in order to access and use
        Pocket Play and paying for all such charges in relation thereto.
        Internet charges will depend on the plan subscribed by You from the
        internet service provider. Pocket Play is compatible on selected
        operating systems and specific versions and Device(s). The download
        procedure of the Application shall be subject to the process specified
        by the operating system of Your Device(s). You need to have a Device
        connected with Internet for download of Pocket Play Application to
        begin and complete. Pocket Play shall not be responsible in case of
        any fluctuation in the Internet connection speed leading to corruption
        of Application file download or any delayed or defective download of the
        Application on Your Device(s). Pocket Play may, at its sole
        discretion, make bug fixes, updates for the installed Application. In
        the event Pocket Play has upgraded the Application or any features
        thereof, You will be required to update Your Device in order to make the
        Device compatible with such upgrades. Pocket Play shall not be
        responsible or liable to You in the event You are unable to access
        Pocket Play or view the Content on Pocket Play due to Your failure
        to upgrade Your Device. 5. REGISTRATION To register for the Pocket Play
        Website/ Application, You shall be required to open an account by
        completing the registration process by providing us with your current,
        complete and accurate information as prompted by the applicable
        registration form; You shall also choose a password and a user name.
        Pocket Play reserves the right to suspend or terminate Your
        registration as Registered User without assigning any reason (including
        for provision of inaccurate, not current or incomplete information
        during the registration process or thereafter). As a Registered User,
        You shall be responsible for safeguarding Your password and for all
        transactions undertaken using Your Username and password. You agree not
        to disclose Your password to any third party and to take sole
        responsibility for any activities or actions under Your account, whether
        or not You have authorized such activities or actions. In particular, as
        a parent or legal guardian, you acknowledge and assume sole
        responsibility to ensure that content which is meant for mature
        audiences, i.e., above the age of majority, is not accessed by children.
        Hence, you may not share your log in credentials with your children. It
        is Your sole responsibility to change Your password immediately if You
        believe that Your password has been compromised. Pocket Play will not
        be responsible for any financial loss, inconvenience or mental agony
        resulting from misuse of Your Username and password in any
        circumstances. You expressly agree to absolve Pocket Play and/or the
        Pocket Play Website/ Application of any responsibility/ liability in
        this regard. We reserve the right to refuse service to anyone for any
        reason at any time. 6. BILLING AND ACCOUNT INFORMATION: By subscribing
        to Pocket Play, you are expressly agreeing that Pocket Play is
        authorized to charge you subscription fee as per your selected plan, any
        other fees for additional services you may purchase, and any applicable
        taxes in connection with your use of Pocket Play's Service through
        any such payment method/ instrument including but not limited to
        credit/debit card, net banking or other payment method/ instrument
        accepted by Pocket Play ("Payment Method") that you have provided.
        The Payment Methods will differ from platform to platform (operating
        systems). The length of the Billing cycle will depend on the type of
        Subscription that you choose when you sign up for services. The
        Subscription Fee will be billed at the beginning of Your Subscription
        membership and on each subsequent renewal (as per the Subscription plan
        chosen by You) unless and until You cancel Your Subscription or the
        Subscription is otherwise suspended or discontinued pursuant to these
        Terms. In the event you desire to discontinue your subscription, you may
        contact us at support@pocketplay.in in order to obtain assistance
        in this regard. Depending on packages and/or value-added services and
        the term of the proposed subscription, You will be required to make
        payment via a Payment Method provided by Pocket Play for accessing
        and browsing Pocket Play. If You wish to avail Pocket Play after
        the expiry of the initial term of Subscribed Packages, You shall prior
        to the expiry of such term, recharge Your account with the then
        prevailing subscription charges. The Subscription or any information
        with respect to same is not transferable to any new or existing account
        under any circumstances. However, Any changes in the Subscription Plan
        opted by You is effective only after the expiry of the current
        Subscription period for which You have already been billed. Accordingly,
        the Subscription as per revised plan opted by You shall be effective
        only after the expiry of the then current Subscription period. You agree
        to provide current, complete and accurate account information for all
        subscriptions. You agree to promptly update your account and other
        information, including your email address and payment information, so
        that we can complete your transactions and contact you as needed. 7.
        SUBSCRIPTION: To be able to view certain premium content ("Premium
        Content"), you will be required to subscribe to any one subscription
        plan ("Plan") on Pocket Play Website/ Application. Any Registered
        User can avail the Subscription Package ("Subscription User"). As
        Subscription User, you will be required to subscribe to Subscription
        Packages made available by Pocket Play from time to time. The
        subscription to Subscription Packages may be subject to additional terms
        specified by Pocket Play. Pocket Play reserves the right to modify
        or discontinue Website/ Application at its sole discretion with or
        without notice to You. Pocket Play shall not be liable to You or any
        third party in any manner, should Pocket Play exercise its right to
        modify or discontinue Website/ Application. Further, Pocket Play
        reserves the right, at any time, with or without notice and without any
        liability to change, terminate or otherwise amend the Subscription
        Plans, Subscription Fees for the Subscription, billing Cycle and any
        part of Pocket Play Services at its sole discretion and at any time.
        Such amendments shall be effective upon posting on the site/app and your
        continued use of the services shall be deemed to be your acceptance of
        such amendments Further, Pocket Play reserves the right to change,
        supplement, alter or remove packaging and introduce base and add on
        packages and/or offer content on individual basis that is subject to
        subscription as it deems fit. Pocket Play does not guarantee the
        availability of a specific content or minimum amount of content that is
        subject to subscription fees. Additionally, Pocket Play shall have
        the right, but not the obligation, to refrain from providing to You any
        Content in the interest of national security or in the event of
        emergency/ war or similar situation or if the Content is anti-national,
        promotes political/ religious propaganda, is against public policy, is
        banned or restricted from being distributed under any applicable law or
        Pocket Play otherwise determine that it is objectionable or is
        derogatory to any person or class of persons, hurts the religious
        sentiments of any religious group or infringes the privacy rights of any
        individual(s) or is not in the interest of Pocket Play's subscribers
        or the general public. 8. PAYMENT GATEWAY: To use the Pocket Play
        service you must provide one or more Payment Methods. You authorize us
        to charge any Payment Method associated to your account in case your
        primary Payment Method is declined or no longer available to us for
        payment of your subscription fee. You remain responsible for any
        uncollected amounts. If a payment is not successfully settled, due to
        expiration, insufficient funds, or otherwise, and you do not cancel your
        account, we may suspend your access to the service until we have
        successfully charged a valid Payment Method. For some Payment Methods,
        the issuer may charge you certain fees, such as foreign transaction fees
        or other fees relating to the processing of your Payment Method. Local
        tax charges may vary depending on the Payment Method used. Check with
        your Payment Method service provider for details. You can update your
        Payment Methods by going to the "Account" page. We may also update your
        Payment Methods using information provided by the payment service
        providers. Following any update, you authorize us to continue to charge
        the applicable Payment Method(s). In a credit card and/or debit card
        and/or net banking and/or cash card transaction and/or any other mode
        available, You must use Your own credit card and/or debit card and/or
        net banking account and/or cash card. You confirm and acknowledge that
        You are aware of the fact that when making any online payment through
        credit card or debit card or net banking account or cash card or via any
        other mode available, You may be directed to an external payment gateway
        page. The payment gateway may redirect You to other website(s)
        maintained or controlled by third parties, and Pocket Play does not
        control such third-party website(s) and hence is not responsible for any
        transactions on such website(s). Pocket Play will not be liable for
        any credit card or debit card or net banking or cash card fraud, and
        Pocket Play will not entertain or address any such grievances or
        issues. You are requested to communicate all grievances related to such
        issues to Your bank or mobile carrier/operator or mobile wallet provider
        who has issued such credit card or debit card or net banking account or
        cash card. Pocket Play shall not be responsible for all or any
        dispute or difference relating to online payment made by You through
        credit card or debit card or net banking account or cash card or via any
        other mode available. Further, Pocket Play will not be responsible
        for any financial loss, inconvenience or mental agony resulting from
        misuse of Your credit card or debit card or net banking account number
        or cash card and other details. Payments once made by credit card or
        debit card or net banking or cash cards or via any other mode available
        shall not be refunded in any circumstances. You are hereby advised to
        keep details of your credit/debit card and net banking confidential and
        do not share any such details with any other third party. 9. NO REFUNDS:
        The Subscription Fees once billed are non-refundable irrespective of
        whether the Subscription has been used by You or not and there are no
        refunds or credits for partially used periods. Following any
        cancellation, however, you will continue to have access to the service
        through the end of current billing period. 10. ACCOUNT ACCESS: The
        member who created the Pocket Play account and whose Payment Method
        is charged (the "Account Owner") has access and control over the Pocket
        Play account and the Pocket Play ready devices that are used to
        access our service and is responsible for any activity that occurs
        through the Pocket Play account. To maintain control over the account
        and to prevent anyone from accessing the account (which would include
        information on viewing history for the account), the Account Owner
        should maintain control over the Pocket Play ready devices that are
        used to access the service and not reveal the password or details of the
        Payment Method associated with the account to anyone. You are
        responsible for updating and maintaining the accuracy of the information
        you provide to us relating to your account. If Pocket Play reasonably
        believes that an account and password is being misused in any manner,
        Pocket Play reserves the right to cancel access rights immediately
        without notice, and block access to all users from that IP address. You
        agree to notify Pocket Play immediately of any unauthorized use of
        your account or any other breach of security. Pocket Play will not be
        liable for any loss that you may incur as a result of someone else using
        your password or account, however, you could be held liable for losses
        incurred by Pocket Play or another party due to someone else using
        your account or password. 11. AVAILABILITY & QUALITY: The availability
        of content(s) to view through Website/ Application will change from time
        to time at the sole discretion of Pocket Play. The quality of the
        display of the streaming video may vary from computer to computer, and
        device to device, and may be affected by a variety of factors, such as
        your location, the bandwidth available through and/ or speed of your
        internet connection, and/ or quality of user’s hardware. You are
        responsible for all internet access charges. Please check with your
        internet provider for information on possible internet data usage
        charges. The time it takes to begin watching Pocket Play content will
        vary based on a number of factors, including your location, available
        bandwidth at the time, the content you have selected and the
        configuration of your Pocket Play ready device. 12. RESTRICTIONS &
        PROHIBITED USES: You agree not to, either directly or through the use of
        any device, software, web-based service, or by other means, copy,
        download, archive, perform, display, upload, publish, transmit or
        retransmit the Content or create any work or material that is derived
        from or based on the Content, rent, lease, duplicate, sub-license,
        assign, pledge, loan, or resell the Content of Pocket Play. You shall
        only use Pocket Play on Device(s) that is under Your exclusive
        control and ownership. You shall not permit any third party to benefit
        from the use or functionality of Pocket Play, either directly or via
        any facility management, time-sharing, service bureau or any other
        arrangement. If You transfer possession of any copy of Pocket Play to
        another party, Your subscription of Pocket Play shall automatically
        get terminated and further actions shall be taken against you as
        prescribed in law and legal remedies available. You shall not use any
        "deep-link", "page-scrape", "robot", "spider" or other automatic
        device(s), program, algorithm or methodology, or any similar or
        equivalent manual process, to access, acquire, copy or monitor any
        portion of Pocket Play or any Content, or in any way reproduce or
        circumvent the navigational structure or presentation of Pocket Play
        or any Content, to obtain or attempt to obtain any materials, documents
        or information through any means not purposely made available through
        Pocket Play. We reserve the right to bar any such activity. You shall
        not probe, scan or test the vulnerability of Pocket Play website or
        any network connected to Pocket Play nor breach the security or
        authentication measures on Pocket Play or any network connected to
        Pocket Play. You may not reverse look-up, trace or seek to trace any
        information on any other user of or visitor to Pocket Play to its
        source. You also agree that You will not take any action that imposes an
        unreasonable or disproportionately large load on the infrastructure of
        Pocket Play or any systems or networks connected to Pocket Play.
        You may not pretend that You are, or that You represent, someone else,
        or impersonate any other individual or entity. Your correspondence or
        business dealings with, or participation in promotions of, third party
        advertisers found on or through Pocket Play, including payment and
        delivery of goods or services, and any other terms, conditions,
        warranties or representations associated with such dealings, are solely
        between You and such third-party advertiser. We shall not be responsible
        or liable for any loss or damage of any sort incurred as the result of
        any such dealings or as the result of the presence of such third party
        advertisers on You acknowledge and agree that Pocket Play is not
        responsible or liable for: 1. the availability or accuracy of such
        websites or resources; or 2. the content, products, or services on or
        available from such websites or resources. Links to such websites or
        resources do not imply any endorsement by Pocket Play of such
        websites or resources or the content, products, or services available
        from such websites or resources. You acknowledge sole responsibility for
        and assume all risks arising from Your use of any such websites or
        resources. It is possible that other users (including unauthorized users
        or "hackers") may post or transmit offensive or obscene materials on
        Pocket Play and that You may be involuntarily exposed to such
        offensive and obscene materials. It also is possible for others to
        obtain personal information about You on the public forum due to Your
        use of Pocket Play, and that the recipient may use such information
        to harass or injure You. We do not approve of such unauthorized uses but
        by browsing/using Pocket Play, You acknowledge and agree that We
        shall not responsible for the use of any personal information that You
        publicly disclose or share with others on Pocket Play. In addition to
        other prohibitions as set forth in the Terms of Service, you are
        prohibited from using the site or its content: i. for any unlawful
        purpose; ii. to solicit others to perform or participate in any unlawful
        acts; iii. to violate any international, federal, provincial or state
        regulations, rules, laws, or local ordinances; iv. to infringe upon or
        violate our intellectual property rights or the intellectual property
        rights of others; v. to harass, abuse, insult, harm, defame, slander,
        disparage, intimidate, or discriminate based on gender, sexual
        orientation, religion, ethnicity, race, age, national origin, or
        disability; vi. to submit false or misleading information; vii. to
        upload or transmit viruses or any other type of malicious code that will
        or may be used in any way that will affect the functionality or
        operation of the Service or of any related website, other websites, or
        the Internet; viii. to collect or track the personal information of
        others; ix. to spam, Phish, harm, pretext, spider, crawl, or scrape;
        (x)for any obscene or immoral purpose; or x. to interfere with or
        circumvent the security features of the Service or any related website,
        other websites, or the Internet. We reserve the right to terminate your
        use of the Service or any related Website/ Application for violating any
        of the prohibited uses. 13. WARRANTIES AND LIMITATIONS ON LIABILITY: You
        expressly agree that your use of the website/ application is at your
        sole risk. The Pocket Play service, website, application and access
        to optional tools is provided "as is" and " as available" without
        warranty, representations or condition of any kind and without any
        endorsement. Its affiliates expressly disclaim any and all warranties of
        any kind, whether express or implied (including, without limitation, the
        implied warranties of merchantability, fitness for a particular use or
        purpose and non- infringement). Neither Pocket Play nor any of its
        affiliates make any warranties or representations about the accuracy or
        completeness of content available on or through the Website/ Application
        or the content of any web sites, Personal Social Media or other internet
        or mobile resources linked to the Website/ Application and assume no
        liability or responsibility for any errors, mistakes, or inaccuracies of
        content. In no case shall Pocket Play, our directors, officers,
        employees, affiliates, agents, contractors, interns, suppliers, service
        providers or licensors be liable for any injury, loss, claim, or any
        direct, indirect, incidental, punitive, special, or consequential
        damages of any kind, including, without limitation lost profits, lost
        revenue, lost savings, loss of data, replacement costs, or any similar
        damages, whether based in contract, tort (including negligence), strict
        liability or otherwise, arising from your use of any of the service or
        any packages procured using the service, or for any other claim related
        in any way to your use of the service or any package, including, but not
        limited to, any errors or omissions in any content, or any loss or
        damage of any kind incurred as a result of the use of the service or any
        content posted, transmitted, or otherwise made available via the
        service, even if advised of their possibility. Because some states or
        jurisdictions do not allow the exclusion or the limitation of liability
        for consequential or incidental damages, in such states or
        jurisdictions, our liability shall be limited to the maximum extent
        permitted by law. In particular, we do not guarantee, represent or
        warrant that your use of our service will be uninterrupted, timely,
        secure or error-free. We do not warrant that the results that may be
        obtained from the use of the service will be accurate or reliable. You
        waive all special, indirect and consequential damages against us. These
        terms will not limit any non-waivable warranties or consumer protection
        rights that you may be entitled to under the laws of your country of
        residence. We shall have no liability whatsoever arising from or
        relating to your use of optional third-party tools. Any use by you of
        optional tools offered through the site is entirely at your own risk and
        discretion and you should ensure that you are familiar with and approve
        of the terms on which tools are provided by the relevant third- party
        provider(s) 14. THIRD-PARTY LINKS: You acknowledge and agree that
        certain content, products and services available via our Service may
        include materials from third-parties. Third-party links on this site may
        direct you to third-party websites that are not affiliated with us. We
        are not responsible for examining or evaluating the content or accuracy
        and we do not warrant and will not have any liability or responsibility
        for any third-party materials or websites, or for any other materials,
        products, or services of third-parties. We are not liable for any harm
        or damages related to the subscriptions or use of services, resources,
        content, or any other transactions made in connection with any
        third-party websites. Please review carefully the third- party's
        policies and practices and make sure you understand them before you
        engage in any transaction. Complaints, claims, concerns, or questions
        regarding third-party products should be directed to the third-party.
        15. USER COMMENTS, FEEDBACK AND OTHER SUBMISSIONS: If, at our request,
        you send certain specific submissions (for example contest entries) or
        without a request from us you send creative ideas, suggestions,
        proposals, plans or other materials, whether online, by email, by postal
        mail, or otherwise (collectively, 'comments'), you agree that we may, at
        any time, without restriction, edit, copy, publish, distribute,
        translate and otherwise use in any medium any comments that you forward
        to us. We are and shall be under no obligation i. to maintain any
        comments in confidence; ii. to pay compensation for any comments; or
        iii. to respond to any comments. We may, but have no obligation to,
        monitor, edit or remove content that we determine in our sole discretion
        are unlawful, offensive, threatening, libelous, defamatory,
        pornographic, obscene or otherwise objectionable or violates any party’s
        intellectual property or these Terms of Service. You will not interfere
        with or disrupt, or attempt to interfere with or disrupt, the operation
        of the Website/ Application or any parts thereof. You will not remove,
        alter, interfere with or circumvent any digital rights management
        mechanism, device or other content protection or access control measure
        including, without limitation Geo-filtering and/or encryption, etc.,
        associated with Website/ Application content. You agree that your
        comments will not violate any right of any third-party, including
        copyright, trademark, privacy, personality or other personal or
        proprietary right. You further agree that your comments will not contain
        defamatory otherwise unlawful, abusive or obscene material, or contain
        any computer virus or other malware that could in any way affect the
        operation of the Service or any related website. You may not use a false
        e-mail address, pretend to be someone other than yourself, or otherwise
        mislead us or third-parties as to the origin of any comments. You are
        solely responsible for any comments you make and their accuracy. We take
        no responsibility and assume no liability for any comments posted by you
        or any third-party. 16. CHANGES TO TERMS OF SERVICE: You can review the
        most current version of the Terms of Service at any time at this page.
        We reserve the right, at our sole discretion, to update, change or
        replace any part of these Terms of Service by posting updates and
        changes to our website. Your continued use of or access to our website
        or the Service following the posting of any changes to these Terms of
        Service constitutes acceptance of those changes. 17. PRIVACY POLICY:
        Your submission of personal information through the subscription is
        governed by our Privacy Policy. 18. RELATIONSHIP: The relationship
        between Pocket Play and You is on a principal-to-principal basis. You
        are in no way Pocket Play’s legal representative, partner or agent
        for any reason whatsoever. 19. NOTICES: Any notices or other
        communications required will be in writing and emailed to Pocket Play
        at support@pocketplay.in for notices made by e-mail, the date of
        receipt will be deemed the date on which such notice is transmitted. In
        the event you have any complaints with respect to any Content on Pocket
        Play, please write to us at support@pocketplay.in with details of
        the objectionable content and Your details including Your name, mobile
        number and such other details as may be requested by Us. Based on the
        complaint raised, we will take reasonable measures to resolve the issue.
        You hereby expressly agree that Pocket Play shall under no
        circumstance be liable to You for any damages whatsoever. Further, You
        hereby expressly agree that Pocket Play makes no representations
        under these Terms of Use that any complaint raised by You will be
        resolved to Your satisfaction. All steps to be taken by Pocket Play
        in this regard shall be at the sole discretion of Pocket Play. 20.
        TERMINATION: Your Membership will continue until terminated. You may
        terminate these Terms of Service at any time by notifying us that You no
        longer wish to use our Services, or when you cease using our site/ App.
        As Subscription User, Your right to use Pocket Play shall
        automatically terminate on the expiry of the term of the Subscription
        Package unless you renew it. Your right to use Pocket Play shall
        automatically terminate if You violate these Terms of Use or any terms,
        rules or guidelines published in connection with Pocket Play. Pocket
        Play reserves the right, in its sole discretion, to suspend or
        terminate Your access to all or any part of Pocket Play, for any
        reason, with or without notice. You agree that upon suspension or
        termination, no amount shall be refunded to You by Pocket Play. 21.
        COPYRIGHT INFRINGEMENT NOTIFICATION: Pocket Play respects the
        intellectual property rights of others. Upon proper notice, Pocket Play
        will remove User Submissions (and any other Website/ Application
        content) that violates copyright law. As per the provisions of
        Information Technology Act, 2000 and any amendments made thereto along
        with the rules framed thereunder and the provisions of the Copyright
        Act, 1957 along with all the amendments made thereto and rules framed
        thereunder, Pocket Play shall remove upon receiving written
        notification of claimed copyright infringement on the Website/
        Application and for processing such claims in accordance with such law.
        If you believe a work has been copied in a way that constitutes
        copyright infringement, please send a notification of claimed
        infringement (an "Infringement Notification") that contains all the
        following information: (i) identification of the copyrighted work
        claimed to have been infringed; (ii) identification of the claimed
        infringing material and information reasonably sufficient to permit
        Pocket Play to locate the material on Website/ Application; (iii)
        information reasonably sufficient to permit Pocket Play to contact
        You, such as an address, telephone number, and, if available, an e-mail
        address; (iv) a statement by You that you have a good faith belief that
        the disputed use is not authorized by the copyright owner, its agent, or
        the law; (v) a statement by You, made under penalty of perjury, that the
        above information in your notification is accurate and that You are the
        copyright owner or authorized to act on the copyright owner's behalf;
        and (vi) Your physical signature. By submitting an Infringement
        Notification, You acknowledge and agree that Pocket Play may forward
        your Infringement Notification and any related communications to any
        users who posted the material identified in such notice. INFRINGEMENT
        NOTIFICATION IS TO BE SENT TO: Pocket Play. 22. ERRORS, INACCURACIES
        AND OMISSIONS: Occasionally there may be information on our site or in
        the Service that contains typographical errors, inaccuracies or
        omissions that may relate to product descriptions, pricing, promotions,
        offers, availability etc. We reserve the right to correct any errors,
        inaccuracies or omissions, and to change or update information or cancel
        subscription(s) if any information in the Service or on any related
        website is inaccurate at any time without prior notice, including after
        you have subscribed the packages. We undertake no obligation to update,
        amend or clarify information in the Service or on any related website,
        including without limitation, pricing information, except as required by
        law. 23. INDEMNIFICATION: You agree to indemnify, defend, and hold
        harmless, Pocket Play, its parent, subsidiaries, affiliates,
        partners, officers, directors, consultants, agents, licensors,
        contractors, sub-contractors, service providers, suppliers, interns and
        employees ("indemnified parties") from and against any and all claims,
        liabilities, damages, losses, costs, expenses, fees (including
        reasonable attorneys' fees and costs) that such indemnified parties may
        incur as a result of or arising from i. any information (including,
        without limitation, your user generated content, feedback, or any other
        content) you (or anyone using your account) submit, post, or transmit on
        or through Pocket Play; ii. your (or anyone using your account's) use
        of Pocket Play; iii. your (or anyone using your account's) violation
        of these terms; or iv. your (or Anyone using your account's) violation
        of any rights of any other person or entity, including, without
        limitation, any copyright, patent, trademark, trade secret or other
        proprietary rights of any person or entity. Pocket Play reserves the
        right, at its own expense, to assume the exclusive defense and control
        of any matter otherwise subject to indemnification by you, in which
        event you will cooperate with Pocket Play in asserting any available
        defenses. This provision shall remain in full force and effect
        notwithstanding any termination of your use of the site. 24.
        SEVERABILITY: In the event that any provision of these Terms of Service
        is determined to be unlawful, void or unenforceable, such provision
        shall nonetheless be enforceable to the fullest extent permitted by
        applicable law, and unenforceable portion shall be deemed to be severed
        from these Terms of Service, such determination shall not affect
        validity and enforceability of any other remaining provisions. 25.
        GOVERNING LAW: These Terms of Service and any separate agreements
        whereby we provide you Services shall be governed by and construed in
        accordance with the laws of India and jurisdiction of Mumbai,
        Maharashtra. 26. ENTIRE AGREEMENT: The failure of us to exercise or
        enforce any right or provision of these Terms of Service shall not
        constitute a waiver of such right or provision. These Terms of Service
        and any policies or operating rules posted by us on this site or in
        respect to the service constitutes the entire agreement and
        understanding between You and Pocket Play and govern Your use of the
        Service, superseding any prior or contemporaneous agreements,
        communications and proposals, whether oral or written, between You and
        Pocket Play (including, but not limited to, any prior versions of the
        Terms of Service). Any ambiguities in the interpretation of these Terms
        of Service shall not be construed against the drafting party. The
        headings used in this agreement are included for convenience only and
        will not limit or otherwise affect these Terms. 27. CUSTOMER SUPPORT: To
        find more information about our service and its features or if you need
        assistance with your account, write it to us on support@pocketplay.in GRIEVANCE REDRESSAL: If you have any grievance with respect
        to any of the Content, Title, Age rating, Synopsis, Parental control
        feature etc. available on our Pocket Play App, you can register a
        complaint with our Grievance Redressal Officer on below mentioned
        details: Grievance Redressal Officer:  Email: support@pocketplay.in One can raise a complaint by contacting our Grievance
        Redressal officer by emailing at support@pocketplay.in. Upon receipt
        of the complaint the same shall be acknowledged within a period of 24
        hours. Our Grievance Redressal team headed by our Grievance Redressal
        Officer will resolve the complaint within 15 days from the date of
        receipt of complaint. Refund Policy This Policy applies to Pocket Play
        Site/ App platforms including without limitation www.pocketplay.in (https://www.pocketplay.in) and other related Site/s or
        App/s, mobile applications and other online features each a “Site/s or
        App/s”. We have provided extensive information for you to view the
        packages before choosing to subscribe to us. If you have any questions
        or reservations, please contact us at support@pocketplay.in prior to
        subscribing to our services. We, being the service providers for
        contents available through SITE or APP, where you are expected to view
        packages of your choice after being paid for subscription;
        unfortunately, all fees to Pocket Play for such services are
        non-refundable. In case, because of any technical glitch at the time of
        online transaction, the transaction does not occur, the amount in
        process of transfer by default goes back into your bank account,
        automatically through Payment Gateway. CHARGEBACKS If we receive a
        chargeback or payment dispute from a credit card company or bank, your
        service and/or subscription will be suspended without notice. Applicable
        chargeback fee will be issued to recover fees passed on to us by the
        credit company, plus any outstanding balances accrued as a result of the
        chargeback(s) must be paid in full before service is restored. Instead
        of issuing a chargeback, contact us to address any billing issues.
        Requesting a chargeback or opening any sort of dispute for a valid
        charge from us is fraud, and is never an appropriate or legal means of
        obtaining a refund. PLEASE READ AND MAKE SURE YOU FULLY UNDERSTAND OUR
        REFUND POLICY PRIOR TO MAKING A PAYMENT.
      </Item>
    </Static>
  );
};

export default Terms;
