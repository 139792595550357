import React from "react";
import Static, { Item } from "../../components/commen/Static";

const About = () => {
  return (
    <Static title={"About Us"}>
      <Item title="">
        Get hooked to binge-worthy content tailored to your taste in your
        language! Pocket Play a product of A S C Digital Private Limited It is a
        video streaming service that offers a wide variety of genres from drama,
        horror, suspense, thriller to comedy & beyond. Binge watch from our
        collection of web series, movies, Pocket Play and more in your regional
        language. Start a Free Trial and your all videos will be on us. Enjoy
        unlimited video streaming and downloads at no payment all year long.
        check Flip through trailers to help choose what to watch first. check
        Personalized content according to your geographical location. check
        Unlimited HD streaming and downloading 24x7. check Personalize your free
        membership plan to suit your need. check Access content anywhere in the
        world. check Download videos for offline viewing-on-the-go.
      </Item>
    </Static>
  );
};

export default About;
