import axios from "axios";
const URL = "https://api.pocketplay.in";
export const url = URL;

export const getHomePage = async () => {
  try {
    const response = await axios.get(`${URL}/user/homepage`);

    if (response.status === 200) {
      return [true, response.data];
    }
    return [false, response.data];
  } catch (error) {
    return [false, error];
  }
};

export const getMovieDetails = async (id) => {
  try {
    const response = await axios.get(`${URL}/movie-name/${id}`);

    if (response.status === 200) {
      return [true, response.data];
    }
    return [false, response.data];
  } catch (error) {
    return [false, error];
  }
};
export const getSubStatus = async (jwt) => {
  try {
    const response = await axios.get(`${URL}/user/subscription-status`, {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    });
    if (response.status === 200) {
      return [true, response.data];
    }
    return [false, response.data];
  } catch (error) {
    return [false, error];
  }
};
export const getSimilarMovies = async (id) => {
  try {
    const response = await axios.get(`${URL}/user/similar-movies/${id}`);

    if (response.status === 200) {
      return [true, response.data];
    }
    return [false, response.data];
  } catch (error) {
    return [false, error];
  }
};

export const getSeriesDetails = async (id) => {
  try {
    const response = await axios.get(`${URL}/series-name/${id}`);

    if (response.status === 200) {
      return [true, response.data];
    }
    return [false, response.data];
  } catch (error) {
    return [false, error];
  }
};

export const getSimilarSeries = async (id) => {
  try {
    const response = await axios.get(`${URL}/user/similar-series/${id}`);

    if (response.status === 200) {
      return [true, response.data];
    }
    return [false, response.data];
  } catch (error) {
    return [false, error];
  }
};

export const loginUser = async (data) => {
  try {
    const response = await axios.post(`${URL}/user/login`, data);

    if (response.status === 200) {
      return [true, response.data];
    }

    return [false, response];
  } catch (error) {
    return [false, error];
  }
};

export const signUpUser = async (data) => {
  try {
    const response = await axios.post(`${URL}/user/signup`, data);

    if (response.status === 200) {
      return [true, response.data];
    }

    return [false, response];
  } catch (error) {
    return [false, error];
  }
};

export const getWatchList = async (jwt) => {
  try {
    const response = await axios.get(`${URL}/user/watchlist`, {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    });

    if (response.status === 200) {
      return [true, response.data];
    }
    return [false, response.data];
  } catch (error) {
    return [false, error];
  }
};

export const checkUserWatchlist = async (jwt, id) => {
  try {
    const response = await axios.get(`${URL}/user/watchlist/${id}`, {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    });

    if (response.status === 200) {
      return [true, response.data];
    }
    return [false, response.data];
  } catch (error) {
    return [false, error];
  }
};

export const addToWatchList = async (jwt, data) => {
  try {
    const response = await axios.put(`${URL}/user/watchlist`, data, {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    });

    if (response.status === 200) {
      return [true, response.data];
    }
    return [false, response.data];
  } catch (error) {
    return [false, error];
  }
};

export const removeFromWatchList = async (jwt, data) => {
  try {
    const response = await axios.put(`${URL}/user/watchlist-remove`, data, {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    });

    if (response.status === 200) {
      return [true, response.data];
    }
    return [false, response.data];
  } catch (error) {
    return [false, error];
  }
};

export const getProfile = async (jwt) => {
  try {
    const response = await axios.get(`${URL}/user/profile`, {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    });
    if (response.status === 200) {
      return [true, response.data];
    }
    return [false, response.data];
  } catch (error) {
    return [false, error];
  }
};

export const updateProfilePic = async (jwt, data) => {
  try {
    const response = await axios.put(`${URL}/user/update-image`, data, {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    });
    if (response.status === 200) {
      return [true, response.data];
    }
    return [false, response.data];
  } catch (error) {
    return [false, error];
  }
};

export const updateUserData = async (jwt, data) => {
  try {
    const response = await axios.put(`${URL}/user/profile`, data, {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    });
    if (response.status === 200) {
      return [true, response.data];
    }
    return [false, response.data];
  } catch (error) {
    return [false, error.response.data];
  }
};

export const getAllRentals = async (jwt) => {
  try {
    const response = await axios.get(`${URL}/user/rented`, {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    });
    if (response.status === 200) {
      return [true, response.data];
    }
    return [false, response.data];
  } catch (error) {
    return [false, error];
  }
};

export const getSubscription = async (jwt) => {
  try {
    const response = await axios.get(`${URL}/user/subscription-status`, {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    });
    if (response.status === 200) {
      return [true, response.data];
    }
    return [false, response.data];
  } catch (error) {
    return [false, error];
  }
};

export const getOtherSubs = async (jwt) => {
  try {
    const response = await axios.get(`${URL}/user/other-subscriptions`, {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    });
    if (response.status === 200) {
      return [true, response.data];
    }
    return [false, response.data];
  } catch (error) {
    return [false, error];
  }
};

export const checkAccess = async (jwt, data) => {
  try {
    const response = await axios.get(
      `${URL}/user/subscription-status`,

      {
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
      }
    );
    if (response.status === 200) {
      return [true, response.data];
    }
    return [false, response.data];
  } catch (error) {
    return [false, error];
  }
};
export const generateRandomString = (length) => {
  var result = "";
  var characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  var charactersLength = characters.length;

  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }

  return result;
};
export const phonePay = async (data, jwt) => {
  try {
    const response = await axios.post(`${URL}/phonepay`, data, {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    });

    if (response.status === 200) {
      return [true, response.data];
    }
    return [false, response];
  } catch (error) {
    return [false, error];
  }
};
export const getAllSubscriptions = async (jwt) => {
  try {
    const response = await axios.get(`${URL}/all-subscriptions`);
    if (response.status === 200) {
      return [true, response.data];
    }
    return [false, response.data];
  } catch (error) {
    return [false, error];
  }
};

export const buySubscription = async (jwt, data) => {
  try {
    const response = await axios.put(`${URL}/user/buy/subscription`, data, {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    });
    if (response.status === 200) {
      return [true, response.data];
    }
    return [false, response.data];
  } catch (error) {
    return [false, error];
  }
};

export const updateSubscription = async (jwt, data) => {
  try {
    const response = await axios.put(`${URL}/user/update-subscription`, data, {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    });
    if (response.status === 200) {
      return [true, response.data];
    }
    return [false, response.data];
  } catch (error) {
    return [false, error];
  }
};

export const rentAMovie = async (jwt, data) => {
  try {
    const response = await axios.post(`${URL}/user/rent`, data, {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    });
    if (response.status === 200) {
      return [true, response.data];
    }
    return [false, response.data];
  } catch (error) {
    return [false, error];
  }
};

export const rentASeries = async (jwt, data) => {
  try {
    const response = await axios.post(`${URL}/user/rent`, data, {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    });
    if (response.status === 200) {
      return [true, response.data];
    }
    return [false, response.data];
  } catch (error) {
    return [false, error];
  }
};

export const searchData = async (data) => {
  try {
    const response = await axios.get(`${URL}/search/${data}`);
    if (response.status === 200) {
      return [true, response.data];
    }
    return [false, response.data];
  } catch (error) {
    return [false, error];
  }
};

export const getUnreadNotifications = async (jwt) => {
  try {
    const response = await axios.get(`${URL}/user/notification/unread`, {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    });
    if (response.status === 200) {
      return [true, response.data];
    }
    return [false, response.data];
  } catch (error) {
    return [false, error];
  }
};

export const getAllNotification = async (jwt) => {
  try {
    const response = await axios.get(`${URL}/user/notification`, {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    });
    if (response.status === 200) {
      return [true, response.data];
    }
    return [false, response.data];
  } catch (error) {
    return [false, error];
  }
};

export const markAsRead = async (jwt, id) => {
  try {
    const response = await axios.put(`${URL}/user/notification/${id}`, {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    });
    if (response.status === 200) {
      return [true, response.data];
    }
    return [false, response.data];
  } catch (error) {
    return [false, error];
  }
};

export const getCategoryData = async (id) => {
  try {
    const response = await axios.get(`${URL}/user/category-data/${id}`);
    if (response.status === 200) {
      return [true, response.data];
    }
    return [false, response.data];
  } catch (error) {
    return [false, error];
  }
};
