import React from "react";
import { useParams } from "react-router-dom";
import VideoCard from "../components/VideoCards/VideoCard";
import Loading from "../components/commen/Loading";
import { getSeriesDetails, getSimilarSeries } from "../utils/apis";

import ReactPlayer from "react-player";
import useStore from "../utils/store";
const SeriesDetails = () => {
  const [data, setData] = React.useState([]);

  const { id: id2 } = useParams();
  const [id, setId] = React.useState(0);
  const user = useStore((state) => state.user);
  const [loading, setLoading] = React.useState(false);
  const [similarSeries, setSimilarSeries] = React.useState([]);
  const [currentEpisode, setCurrentEpisode] = React.useState(0);
  const [description, setDescription] = React.useState(false);

  const getSeriesDetailsHandler = async () => {
    setLoading(true);
    const [success, response] = await getSeriesDetails(id2);
    if (success) {
      setData(response?.[0]);
      setId(response?.[0]?._id);
    }
    setLoading(false);
  };

  const getSimilarSeriesHandler = async () => {
    setLoading(true);
    const [success, response] = await getSimilarSeries(id);
    if (success) {
      setSimilarSeries(response);
    }
    setLoading(false);
  };

  React.useEffect(() => {
    getSeriesDetailsHandler();
    getSimilarSeriesHandler();
  }, [id2]);
  console.log(id);

  if (loading) {
    return <Loading />;
  }
  return (
    <div className=" md:px-32">
      <div className="relative aspect-video w-full object-cover md:aspect-[10/4]">
        <ReactPlayer url={data?.trailer} controls width="100%" height="100%" />
      </div>
      <div className="flex  flex-row flex-wrap items-center justify-between gap-6 rounded py-1.5 px-8 text-black  md:gap-10 md:bg-primary-100 md:text-white">
        <div className="flex flex-row flex-wrap items-center gap-6">
          <p className="text-sm font-[600]">{data?.name}</p>
          <div className="flex gap-1">
            {data?.genres?.map((item, index) => (
              <>
                <p className="text-xs font-[400]">{item.name}</p>
                {data?.genres?.length > 1 &&
                  data?.genres?.length !== index + 1 && (
                    <p className="text-xs font-[400]">|</p>
                  )}
              </>
            ))}
          </div>
          <p className="text-xs font-[400]">{data?.duration}</p>
          <p className="text-xs font-[400]">{data?.language}</p>
        </div>
        <div className="flex gap-6"></div>
      </div>
      <div className="flex flex-col  gap-3 py-4 px-8  md:pt-8 md:pb-5">
        <div className="flex flex-col gap-3">
          <p className="text-xs">
            Description:
            <br />
            <span className="text-xs font-[400] text-text">
              {data?.description?.slice(
                0,
                description ? data?.description?.length : 100
              )}
              <button
                className="ml-1 text-xs font-[400] text-primary-100"
                onClick={() => setDescription(!description)}
              >
                See {description ? "less" : "more"}
              </button>
            </span>
          </p>
          <p className="text-xs">
            Age-Limit:
            <br />
            <span className="text-xs font-[400] text-text">
              {data?.ageLimit}
            </span>
          </p>
          <p className="text-xs">
            Episodes:
            <br />
            <span className="text-xs font-[400] text-text">
              {data?.episodes?.length}
            </span>
          </p>
        </div>
        {/* {add episodes} */}

        {data.cast?.length > 0 && (
          <>
            <p className="text-xs">Cast:</p>
            <div className="flex flex-wrap gap-4 text-white">
              {data.cast?.map((item, index) => (
                <div className="flex flex-col  gap-2 text-xs">
                  <img
                    src={item.image}
                    alt={item.name}
                    className="h-44 w-28 rounded-md object-cover"
                  />
                  <div>
                    <p>{item.name}</p>
                    <p className="text-text">{item.role}</p>
                  </div>
                </div>
              ))}
            </div>
          </>
        )}
      </div>
      <VideoCard
        episode
        data={{
          name: "Episodes",
          imageType: "Horizontal",
          content: data?.episodes?.map((item, index) => ({
            kind: "series",
            item: item,
          })),
        }}
        onClick={(item) => {
          setCurrentEpisode(item);
        }}
      />
      {similarSeries.length > 0 && (
        <VideoCard
          data={{
            name: "You might also like",
            imageType: "Vertical",
            content: similarSeries.map((item, index) => ({
              kind: "series",
              item: item,
            })),
          }}
        />
      )}
    </div>
  );
};

export default SeriesDetails;
