import React from "react";
import { BsCollectionPlay } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import Modal from "./commen/Modal";

const MovieModal = ({ setWatch, data, RentHandler, access }) => {
  const navigate = useNavigate();
  return (
    <Modal onClose={() => setWatch(false)} height="a">
      <div className="flex flex-col  gap-3 rounded-lg bg-secondary3 py-5">
        <p className="text-center text-lg font-semibold text-white">
          Select Your Prefrence
        </p>
        <div className="flex flex-col gap-3 px-5">
          {/* <button
            className="flex items-center gap-2 rounded-lg bg-primary-100 px-5 py-2 text-white"
            onClick={RentHandler}
          >
            <FaDollarSign className="text-xl" />
            Rent Now - ₹{data?.price}
          </button> */}
          <button
            className="flex items-center gap-2 rounded-lg bg-primary-100 px-5 py-2 text-white"
            onClick={() => {
              navigate("/subscriptions", {
                state: {
                  subs: access?.subscription,
                },
              });
            }}
          >
            <BsCollectionPlay className="text-xl" />
            Buy Subscription
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default MovieModal;
