import React from "react";
import Static, { Item } from "../../components/commen/Static";

const RefundPolicy = () => {
  return (
    <Static title={"Refund Policy"}>
      <Item title={""}>
        REFUND POLICY
        <br />
        <br />
        This Privacy Policy (“Privacy Policy”) applies to Pocket Play Site/ App
        under company "A S C Digital Private Limited" platforms including without
        limitation www.pocketplay.in (https://www.pocketplay.in) and other
        related Site/s or App/s, mobile applications and other online features
        each a “Site/s or App/s”).
        <br />
        <br />
        We have provided extensive information for you to view the packages
        before choosing to subscribe to us. If you have any questions or
        reservations, please contact us at support@pocketplay.com prior to
        subscribing to our services.
        <br />
        <br />
        We, being the service providers for contents available through SITE or
        APP, where you are expected to view packages of your choice after being
        paid for subscription; unfortunately, all fees to Pocket Play TV for
        such services are non-refundable.
        <br />
        <br />
        In case, because of any technical glitch at the time of online
        transaction, the transaction does not occur, the amount in process of
        transfer by default goes back into your bank account, automatically
        through Payment Gateway.
        <br />
        <br />
        CHARGEBACKS
        <br />
        <br />
        If we receive a chargeback or payment dispute from a credit card company
        or bank, your service and/or subscription will be suspended without
        notice. Applicable chargeback fee will be issued to recover fees passed
        on to us by the credit company, plus any outstanding balances accrued as
        a result of the chargeback(s) must be paid in full before service is
        restored. Instead of issuing a chargeback, contact us to address any
        billing issues. Requesting a chargeback or opening any sort of dispute
        for a valid charge from us is fraud, and is never an appropriate or
        legal means of obtaining a refund.
        <br />
        <br />
        PLEASE READ AND MAKE SURE YOU FULLY UNDERSTAND OUR REFUND POLICY PRIOR
        TO MAKING A PAYMENT.
        <br />
        <br />
      </Item>
    </Static>
  );
};

export default RefundPolicy;
