import React from "react";
import { useNavigate } from "react-router-dom";
import Loading from "../components/commen/Loading";
import Banners from "../components/VideoCards/Banners";
import VideoCard from "../components/VideoCards/VideoCard";
import { getCategoryData } from "../utils/apis";
import useStore from "../utils/store";

const Home = () => {
  const activeCategory = useStore((state) => state.activeCategory);

  const navigate = useNavigate();
  const [loading, setLoading] = React.useState(true);
  const [data, setData] = React.useState([]);
  const user = useStore((state) => state.user);
  const getCategoryDataHandler = async () => {
    setLoading(true);
    const [success, data] = await getCategoryData(activeCategory);
    if (success) {
      setData(data);
    }
    setLoading(false);
  };

  React.useEffect(() => {
    if (activeCategory) {
      getCategoryDataHandler();
    }
  }, [activeCategory]);

  if (loading) return <Loading />;
  return (
    <div>
      <Banners data={data?.banners} />
      {data?.subcategories?.map((item) => {
        return <VideoCard data={item} />;
      })}
    </div>
  );
};

export default Home;
